.Details{
    margin-top: -4px;
    .data{
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .mission-content{                 
        padding: 25px;
        flex-shrink: 0;
        border-radius: 15px;
        background-color: white;

    }
    .mission-type{
        color: var(--Grey-01, #A7AEC1);
        text-align: right;

       
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
    }
    .mission-address{
        color: var(--Black-01, #2C3659);
        text-align: right;                  
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
    }
    .mission-reward{
        display: flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: rgba(112, 212, 75, 0.11);
        color: #70D44B;
        text-align: right;
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }
    .created-at{
        display: flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        color: var(--Grey-01, #A7AEC1);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    .mission-status{
        align-items: center;
    }
    .arrow:focus{
        outline: none;
    }
    .inprogress{
        display: flex;        
        height: 28.459px;
        padding: 8.757px 13.135px;
        justify-content: center;
        align-items: center;
        gap: 10.946px;
        flex-shrink: 0;
        border-radius: 54.73px;
        background: var(--Secondary-Amber-50, #FFFBEB);
        color: var(--Secondary-Amber-500-base, #F6A723);
        text-align: center;
        font-family: MadaniArabic-Regular;
        font-size: 10.946px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 17.514px */
    }
    .not-assigned-yet{
        display: flex;
        width: 93px;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--Lgrey-02, #F9F9F9);
        color: var(--Grey-01, #A7AEC1);
        text-align: right;      
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }
    .popover-bottom-left{
        background-color: white;

        left: 0;
        right: auto;
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform-origin: top right;
    }
    .popover-bottom-left .popover-arrow{
        display: none;
    }
    .three-dots-li{
        display: flex;
        justify-content: start;
        color: #64748B;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        margin-bottom: 15px;
    }
    .three-dots-li-delete{
        display: flex;
        justify-content: start;
        color: #EB4335;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
        margin-bottom: 15px;
    }
    .mission-history{
        display: flex;
        width: 50%;
        height: 28.459px;
        padding: 8.757px 13.135px;
        justify-content: center;
        align-items: center;
        gap: 10.946px;
        flex-shrink: 0;
        border-radius: 54.73px;
        background: #b02818;
        margin-top: 10px;
        margin-left: 10px;
        color: var(--secondary-amber-500-base, #eeeeee);
        text-align: center;
        font-family: MadaniArabic-Regular;
        font-size: 10.946px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }
    .mission-details{
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F9F9F9;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
    .close-icon{
        // display: flex;
        // align-self: center;
    }
    .mission-range{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;

        
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
    }
    .range{
        display: inline-flex;
        padding: 13px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--Lgrey-02, #F9F9F9);
        color: var(--Grey-01, #A7AEC1);
        text-align: right;
        
        /* Label/L6 Plus - 12 Semi Bold - Lh 130% */
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
        overflow-y: hidden;
    }
    .mission-assign-txt{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;

        /* body/medium/semibold */
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        letter-spacing: 0.2px;
    }
    .assigned-at{
        color: #A7AEC1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }
    .user-name{
        color: var(--daBlack-03---Line, #292E32);
       text-align: right;
       font-family: MadaniArabic-Medium;
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: 130%; /* 18.2px */
    }
    .discription{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;

        /* body/medium/semibold */
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        letter-spacing: 0.2px;
    }
    .description-content{
        color: var(--Greyscale-500, #64748B);
        text-align: right;

        /* body/small/regular */
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
    .questions-section{
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .question-address{
        color: var(--Black-01, #2C3659);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
    }
    .accordion-title{
        color: var(--Black-01, #2C3659);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
    }
    .added-at{
        margin-top: 10px;
        display: flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--Lgrey-02, #F9F9F9);
        color: var(--Grey-01, #A7AEC1);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    .description{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;

        /* body/medium/semibold */
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        letter-spacing: 0.2px;
    }
    .details{
        color: var(--Greyscale-500, #64748B);
        text-align: right; 
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
    }
    .quesion-title{
        color: var(--Black-01, #2C3659);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    .selected-answer{
        display: inline-flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #F1FAEE;
        color: #70D44B;
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
    }
    .answer{
        display: inline-flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        color: var(--Greyscale-500, #64748B);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
    }
    .userName{
        color: var(--Neutral-Dark-Grey, #9C9CA4);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;  
    }
    .ranking{
        position: relative;

        height: 55px;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #D8D013;
        background: #FFF;
    }
    .tag{
        position: absolute;         
        left: -15px;
        bottom: 25px;
    }
    .score{
        display: inline-flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: rgba(112, 212, 75, 0.11);
        color: #70D44B;
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    .ranking-num{
        color: var(--Greyscale-900, #0F172A);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
    }
    .img-container{
        position: relative;
        // width: 100%;
        max-width: 600px; /* Set the maximum width of the container as needed */
    } 
    .image-overlay {
        position: absolute;
        top: 9px;
        left: -8px;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
    .attachment{
        border-radius: 10px;
        border: 1px solid #F1F5F9;
        background: var(--White-General, #FFF);            
        height: 80px;
        flex-shrink: 0;
    }
    .farm-data{
        color: var(--Black-01, #2C3659);
        text-align: right;

        /* Headings/H6 - 14 Bold - Lh 130% */
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 18.2px */
    }
    .sponser{
        display: flex;
        
        padding: 36px 46.917px 34.48px 46.083px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12.389px;
        border-radius: 15.668px;
        border: 1.045px solid #E2E8F0;
        background: #FFF;
    }
    .sponserName{
        color: #124734;
        font-family: MadaniArabic-Regular;
        font-size: 16.358px;
        font-style: normal;
        font-weight: 400;
        line-height: 31.229px; /* 190.909% */
    }
    .sponser-icon{
        width: 50.328px;
        height: 42.462px;
        flex-shrink: 0;
        border-radius: 30px;
        margin-right: 20px;
    }
    
}