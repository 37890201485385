.MissionType{
    // width: 1400px;
    .mission-type-txt{
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F9F9F9;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
    .select-mission-type{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;

        /* Heading/H3 */
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 30px */
        letter-spacing: 0.2px;
    }
    .input-search{
        padding-right: 45px;
    }
    .input-search::placeholder{
        color: var(--Greyscale-500, #64748B);
        text-align: right;       
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }
    .scrollable-list{
        // overflow-y: scroll;
        height: 10%;
    }
    .list-text{
        color: #64748B;
    text-align: right;

    /* Headings/H5 - 16 Bold - Lh 130% */
    font-family: MadaniArabic-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    }
    .done{
        display: flex;
        width: 142px;
        padding: 8px 0px 9px 0px;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background: #124734;
        color: #FFF;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        cursor: pointer;

    }
    .cancel{
        display: flex;
        width: 135px;
        height: 37px;
        padding: 8px 44px 9px 41px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #EEE;
        background: #FFF;
        display: flex;
        // padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }
    
}