.AllTransactions{
     
    
    //     font-size: 
    //  
    //   
    //     background-color: red !important;
    //   }
    
    //   .table tr {
    //     // border-radius: 20px;
    //   }
    
    //   
    
    //   tr td:nth-child(1),
    //   tr th:nth-child(1) {
    //     border-radius: 0.625rem 0 0 0.625rem;
    //   }
    padding-top: 20px;
    padding: 20px;
    background-color: #F8FAFC;
         .data-row{
             padding: 20px;
             background-color: #FFF;
             text-align: center;
        } 
         .bannerText{
            color: #64748B;
            font-family: MadaniArabic-Regular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
       }
       .report{
        width: 182px;
        height: 103px;
        flex-shrink: 0;
        border-radius: 9.794px;
        border: 1px solid #E2E8F0;
        background: #FFF;
       }
       .amount{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;

        /* Heading/H3 */
        font-family: MadaniArabic-SemiBold;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 30px */
        letter-spacing: 0.2px;
    }
    .coin{
        color: #70D44B;
        text-align: right;

        /* body/small/medium */
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
    .report-title{
        color: var(--Greyscale-500, #64748B);
        text-align: right;
                        
        /* body/small/regular */
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }


        .breeder-name{
            color: var(--Greyscale-900, #0F172A);
            text-align: center;
            font-family: MadaniArabic-SemiBold;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; 
            letter-spacing: 0.2px;
        }
        .breeder-email{
            color: var(--Greyscale-900, #0F172A);
            text-align: center; 
            font-family: MadaniArabic-SemiBold;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;  
            letter-spacing: 0.2px;
        }
        .breeder-phone{
            color: #28303F;
            text-align: center;
            font-family: MadaniArabic-Medium;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 22.4px */
        }
        .breeder-active{
            color: #70D44B;
            text-align: center;
            font-family: MadaniArabic-Medium;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 22.4px */
        }
        .breeder-disable{
            color: #EB001B;
            text-align: center;
            font-family: MadaniArabic-Medium;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 22.4px */
        }
        .breeder-eval{
            color: #28303F;
            text-align: center;
            font-family: MadaniArabic-Medium;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;  
        }
        .breeder-city{
            color: #28303F;
            text-align: center;
            font-family: MadaniArabic-Medium;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; 
        }
        .btn-pagination-active{
            background-color: #70D44B;
            color: #eeeeee;
        }
           .btn-pagination-active:hover{
            background-color: #a4d791;
            color: #eeeeee;
        }
        .pagination{
            padding-bottom: 10px;
        }
        ::-webkit-scrollbar {
            width: 8px; /* Width of the entire scrollbar */
          }
          ::-webkit-scrollbar-thumb {
            background-color: #70D44B; /* Color of the scrollbar handle */
            border-radius: 25px; /* Roundness of the scrollbar handle */
          }
          .mission-type-txt{
            display: flex;
            height: 40px;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #F9F9F9;
            color: var(--Greyscale-500, #64748B);
            text-align: right;
            font-family: MadaniArabic-Regular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 19.2px */
        }
        .select-mission-type{
            color: var(--Greyscale-900, #0F172A);
            text-align: right;
    
            /* Heading/H3 */
            font-family: MadaniArabic-SemiBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%; /* 30px */
            letter-spacing: 0.2px;
        }
        .input-search{
            padding-right: 45px;
        }
        .input-search::placeholder{
            color: var(--Greyscale-500, #64748B);
            text-align: right;       
            font-family: MadaniArabic-Regular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 22.4px */
        }
        .scrollable-list{
            // overflow-y: scroll;
            height: 10%;
        }
        .list-text{
            color: #64748B;
        text-align: right;
    
        /* Headings/H5 - 16 Bold - Lh 130% */
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
        }
        .done{
            display: flex;
            width: 142px;
            padding: 8px 0px 9px 0px;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            background: #124734;
            color: #FFF;
            font-family: MadaniArabic-Medium;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 114.286% */
            cursor: pointer;
        }
        .cancel{
            width: 135px;        
            padding: 2px 29px 9px 26px;
            flex-shrink: 0;
            border-radius: 15px;
            border: 1px solid #EEE;
            background: #FFF;
            display: flex;
            
            justify-content: center;
            align-items: center;
            gap: 10px;
            cursor: pointer;
        }
        .assign-type-active{
            display: inline-flex;
            height: 40px;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            color: #70D44B;
            text-align: right;
            border-radius: 8px;
            background: #F1FAEE;
            /* body/medium/bold */
            font-family: MadaniArabic-SemiBold;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 22.4px */
            cursor: pointer;
    
        }
        .assign-type{
            display: inline-flex;
            height: 40px;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            border-radius: 8px;
            background: #F9F9F9;
            color: var(--Greyscale-500, #64748B);
            text-align: right;
    
            /* body/medium/medium */
            font-family: MadaniArabic-Medium;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 22.4px */
            letter-spacing: 0.2px;
            cursor: pointer;
        }
        .number{
            color: #70D44B;
            font-family: MadaniArabic-Medium;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
        }

}