.date-picker-input{
display: flex;
width: 498px;
height: 48px;
padding: 17px 23px 17px 26px;
justify-content: center;
align-items: flex-start;
gap: 339px;
flex-shrink: 0;
border-radius: 15px;
border: 1px solid #E2E2E2;
background: #FFF;
background-image: url('../../../../../Assets//Icons//Calender.jpeg');
background-size: 4%;  
background-repeat: no-repeat;
background-position: 20px center; 
padding-left: 5px;
}
.date-picker-input:focus{
    outline: none;
 }