.UsersFilter{
    .add_title{
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F9F9F9;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
      }
      .head{
        color: #333;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .mission-address-input{
        display: flex;
        width: 100%;
        height: 55px;
        padding-right: 10px;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
       }
    .mission-address-input:focus{
        outline: none;
     }
     .mission-address-input::placeholder{
        color: #9BA0B1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .add-btn{
        display: inline-flex;
        padding: 8px 37.5px 9px 37.5px;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background: #124734;
        color: #FFF;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;  
        cursor: pointer;
      }
    .cancel-btn{
      display: flex;
      width: 135px;
      height: 53px;
      padding: 8px 44px 9px 41px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 15px;
      border: 1px solid #EEE;
      background: #FFF;
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    
   }
   .add-actions{
   
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
   }
   .txt{
        color: #333;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
   }
   .input-search{
    padding-right: 45px;
}
.input-search::placeholder{
    color: var(--Greyscale-500, #64748B);
    text-align: right;       
    font-family: MadaniArabic-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
}
.scrollable-list{
    // overflow-y: scroll;
    height: 10%;
}
   .list-text{
    color: #64748B;
    text-align: right;

    /* Headings/H5 - 16 Bold - Lh 130% */
    font-family: MadaniArabic-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    }
}